import { useEffect, useState } from "react";
import { Box, Card, CardHeader, makeStyles } from "@material-ui/core";
import { qtyStyles } from "../styles";
import mtLogo from "../../../assets/images/mtlogo.png";
import {
	renderError,
	renderLoading,
	renderReloading,
} from "../../../core/providers/PlaceReportProvider/PlaceReportProvider";
import { useLocation, useParams } from "react-router-dom";
import { getPublicPlace, Place } from "../../../core/system/places";
import { todaysDateRange } from "../ReportsSection";

const useStyles = makeStyles(qtyStyles);
const RELOAD_INTERVAL = 1 * 60 * 1000; // 1 minute

const PublicQuantityReport = () => {
	const classes = useStyles();
	const { id } = useParams<{ id?: string }>();
	const [placeReport, setPlaceReport] = useState<Place>();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [retryTimestamp, setRetryTimestamp] = useState(0);
	const location = useLocation();

	// Parse query parameters
	const qtyToday =
		new URLSearchParams(location.search).get("qty-today") === "true";

	const isReloading =
		loading && !!placeReport && placeReport.id?.toString() === id;

	useEffect(() => {
		let timeoutHandle: NodeJS.Timeout | null = null;

		(async function loadReport() {
			if (RELOAD_INTERVAL) {
				timeoutHandle && clearTimeout(timeoutHandle); // Clear any pre set timer
				timeoutHandle = setTimeout(loadReport, RELOAD_INTERVAL);
			}

			if (id) {
				try {
					setError(null);
					setLoading(true);

					const report = await getPublicPlace(
						id,
						qtyToday
							? { start_date: todaysDateRange.from, end_date: todaysDateRange.to }
							: undefined
					);
					setPlaceReport(report!);
					setLoading(false);
				} catch (err) {
					console.log(err);
					setLoading(false);
					setError(err as Error);
				}
			}
		})();

		return () => {
			timeoutHandle && clearTimeout(timeoutHandle);
		};
	}, [id, qtyToday, retryTimestamp]);

	const qty = placeReport?.lp_qty;
	// Calculate dynamic font size based on the length of the quantity
	const baseFontSize = 25; // Maximum font size in rem
	const qtyLength = qty?.toLocaleString().length || 0;
	const dynamicFontSize = Math.min(baseFontSize, baseFontSize / (qtyLength / 6));

	return (
		<>
			{loading && !isReloading && renderLoading()}
			{isReloading && renderReloading()}
			{error && renderError(error, () => setRetryTimestamp(Date.now()))}
			{!error && (!loading || isReloading) && (
				<div
					style={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						placeItems: "center",
						justifyContent: "center",
					}}
				>
					<Card
						style={{
							padding: "1rem",
							maxHeight: "98%",
							width: "98%",
							background: "#a5cbf0",
							borderRadius: 8,
						}}
					>
						<Box className={classes.content}>
							<CardHeader
								style={{ fontSize: "2.5rem" }}
								title={qtyToday ? `Quantity Today` : `Quantity`}
							/>
							<h1
								style={{ fontSize: `${dynamicFontSize}rem` }}
								className={classes.qty}
							>
								{qty?.toLocaleString()}
							</h1>
							<p className={classes.name}>{placeReport?.name}</p>
						</Box>
						<Box className={classes.footer}>
							<div>
								<img
									src={`https://api.3hd.us/app/qrcode_gen.create?data=${
										process.env.REACT_APP_QR_BASENAME || "3hd.us"
									}/${placeReport?.beacon_id}`}
									alt=""
								/>
								<p style={{ margin: 0 }}>Details</p>
							</div>
							<Box>
								Powered By{" "}
								<img
									src={mtLogo}
									alt="Momenttrack trademark"
									style={{
										maxHeight: 16,
										verticalAlign: "middle",
										marginLeft: "8px",
									}}
								></img>
							</Box>
						</Box>
					</Card>
				</div>
			)}
		</>
	);
};

export default PublicQuantityReport;
