import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardHeader, makeStyles } from "@material-ui/core";
import { qtyStyles } from "../styles";
import mtLogo from "../../../assets/images/mtlogo.png";
import ContentCopyIcon from "@material-ui/icons/FileCopy";
import { useHistory } from "react-router-dom";
import PublicIcon from "@material-ui/icons/Public";
import { useDateRange } from "../../../core/components/DateRangePicker";
import moment from "moment";
import { getPlace, Place } from "../../../core/system/places";
import { todaysDateRange } from "../ReportsSection";
import {
	renderError,
	renderLoading,
	renderReloading,
} from "../../../core/providers/PlaceReportProvider/PlaceReportProvider";

const useStyles = makeStyles(qtyStyles);
const RELOAD_INTERVAL = 1 * 60 * 1000; // 1 minute

const QuantityReport: React.FC<{
	qtyToday?: boolean;
	id: number;
	dateRange?: {
		from: Date;
		to: Date;
	};
}> = ({ qtyToday = false, id }) => {
	const classes = useStyles();
	const dateRange = useDateRange();

	const [placeReport, setPlaceReport] = useState<Place>();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);

	const isReloading = loading && !!placeReport && placeReport.id === id;

	useEffect(() => {
		let timeoutHandle: NodeJS.Timeout | null = null;

		(async function loadReport() {
			if (RELOAD_INTERVAL) {
				timeoutHandle && clearTimeout(timeoutHandle); // Clear any pre set timer
				timeoutHandle = setTimeout(loadReport, RELOAD_INTERVAL);
			}

			if (id) {
				try {
					setError(null);
					setLoading(true);

					const report = await getPlace(
						id,
						qtyToday
							? { start_date: todaysDateRange.from, end_date: todaysDateRange.to }
							: { start_date: dateRange?.from, end_date: dateRange?.to }
					);
					setPlaceReport(report!);
					setLoading(false);
				} catch (err) {
					console.log(err);
					setLoading(false);
					setError(err as Error);
				}
			}
		})();

		return () => {
			timeoutHandle && clearTimeout(timeoutHandle);
		};
	}, [dateRange?.from, dateRange?.to, id, qtyToday]);

	const embedUrl = `${window.location.origin}/quantity-report/${placeReport?.id}?qty-today=${qtyToday}`;
	const iframeCode = `<iframe src="${embedUrl}" width="100%" height="100%" frameborder="0" title="${placeReport?.name} Quantity Report" allowfullscreen></iframe>`;

	const copyToClipboard = () => {
		navigator.clipboard.writeText(iframeCode);
		alert("Embed code copied to clipboard!");
	};

	const qty = placeReport?.lp_qty;
	// Calculate dynamic font size based on the length of the quantity
	const baseFontSize = 25; // Maximum font size in rem
	const qtyLength = qty?.toLocaleString().length || 0;
	const dynamicFontSize = Math.min(baseFontSize, baseFontSize / (qtyLength / 4));

	const history = useHistory();
	return (
		<>
			{loading && !isReloading && renderLoading()}
			{isReloading && renderReloading()}
			{error && renderError(error, () => {})}
			{!error && (!loading || isReloading) && (
				<Card
					style={{
						padding: "1rem",
						background: "#a5cbf0",
						borderRadius: 8,
						margin: "1rem 0",
						maxWidth: "100%",
					}}
				>
					<Box className={classes.content}>
						<Button
							className={classes.embed_btn}
							startIcon={
								placeReport?.public_access ? <ContentCopyIcon /> : <PublicIcon />
							}
							variant="outlined"
							onClick={() => {
								placeReport?.public_access
									? copyToClipboard()
									: history.push(`/places?edit=${placeReport?.id}`);
							}}
						>
							{placeReport?.public_access
								? "Copy Iframe Code"
								: "Enable public access"}
						</Button>

						<CardHeader
							style={{ fontSize: "2.5rem" }}
							title={
								qtyToday
									? "Quantity Today"
									: dateRange?.from
									? `Quantity For ${moment(dateRange?.from).format("MMM DD")} - ${moment(
											dateRange?.to
									  ).format("MMM DD")}`
									: `Quantity`
							}
						/>
						<h1 className={classes.qty} style={{ fontSize: `${dynamicFontSize}rem` }}>
							{qty?.toLocaleString()}
						</h1>
						<p className={classes.name}>{placeReport?.name}</p>
					</Box>
					<Box className={classes.footer}>
						<div>
							<img
								src={`https://api.3hd.us/app/qrcode_gen.create?data=${
									process.env.REACT_APP_QR_BASENAME || "3hd.us"
								}/${placeReport?.beacon_id}`}
								alt=""
							/>
							<p style={{ margin: 0 }}>Details</p>
						</div>
						<Box>
							Powered By{" "}
							<img
								src={mtLogo}
								alt="Momenttrack trademark"
								style={{
									maxHeight: 16,
									verticalAlign: "middle",
									marginLeft: "8px",
								}}
							></img>
						</Box>
					</Box>
				</Card>
			)}
		</>
	);
};

export default QuantityReport;
