import React, { useState } from "react";
import * as Yup from "yup";
import { PersonInviteDialogProps } from "./types";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import usePersons from "../../core/providers/PersonsProvider/use-persons";

const PersonInviteDialog: React.FC<PersonInviteDialogProps> = ({
	children,
	title,
	initialValues,
	disabledInput,
	...dialogProps
}) => {
	const [, loading, { sendInvite }] = usePersons();
	const [email, setEmail] = useState("");

	const handleSubmit = async () => {
		try {
			const validatedEmail = await Yup.string()
				.trim()
				.required("Please enter a valid email address.")
				.email("Please enter a valid email address.")
				.validate(email || initialValues?.email);
			await sendInvite(validatedEmail as string);
			setEmail("");
			dialogProps.onClose && dialogProps.onClose({}, "backdropClick");
		} catch (err: any) {
			alert((err as Error).message);
		}
	};

	return (
		<Dialog {...dialogProps}>
			<DialogTitle>{title || "Send user invitation"}</DialogTitle>
			<DialogContent>
				<Box py={2}>
					<TextField
						autoFocus
						type="email"
						label="Email address"
						color="secondary"
						placeholder="abc@example.com"
						required
						size="small"
						fullWidth
						disabled={disabledInput || loading}
						value={email || initialValues?.email || ""}
						onChange={ev => setEmail(ev.target.value)}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				{loading && <CircularProgress size={24} color="secondary" />}
				{!loading && (
					<Button
						onClick={handleSubmit}
						color="secondary"
						disabled={loading || (!email.length && !initialValues?.email)}
					>
						Send
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default PersonInviteDialog;
