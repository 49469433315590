import React, {
	useState,
	useImperativeHandle,
	useRef,
	forwardRef,
} from "react";
import { ProductInputDialogProps, ProductInputDialogApi } from "./types";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import useNotify from "../../core/hooks/use-notify";
import useProducts from "../../core/hooks/use-products";
import ProductForm, {
	ProductFormData,
	ProductFormRef,
} from "../../core/forms/ProductForm";

const ProductInputDialog: React.ForwardRefRenderFunction<
	ProductInputDialogApi,
	ProductInputDialogProps
> = (props, ref) => {
	const productForm = useRef<ProductFormRef>(null);
	const notify = useNotify();
	const [open, setOpen] = useState(false);
	const [, loading, productActions] = useProducts();

	const handleSubmit = async (data: ProductFormData) => {
		const newProduct = await productActions.create(data);
		if (!newProduct) {
			notify("Failed");
		} else {
			notify(`Added product "${newProduct?.part_number}"`);
			setOpen(false);
		}
	};

	useImperativeHandle(ref, () => ({ openDialog: () => setOpen(true) }), []);

	return (
		<Dialog
			open={open}
			fullWidth
			maxWidth="sm"
			disableEscapeKeyDown={loading}
			onClose={() => setOpen(false)}
		>
			<DialogTitle>{props.title}</DialogTitle>
			<DialogContent>
				<Box pt={2} pb={4}>
					<ProductForm
						ref={productForm}
						onSubmit={handleSubmit}
						disabled={loading}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				{loading && <CircularProgress size={24} />}
				<Button onClick={() => setOpen(false)} disabled={loading}>
					Cancel
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={() => productForm.current?.submitForm()}
					disabled={loading}
				>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default forwardRef(ProductInputDialog);
