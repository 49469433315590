import {
	Box,
	Dialog,
	DialogContent,
	IconButton,
	makeStyles,
	Typography,
	TextField,
	FormControlLabel,
	Checkbox,
	Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EmailIcon from "../../../assets/images/inbox.png";
import { useState } from "react";
import { SendCSVDialogProps } from "./types";
import { createStyles } from "@material-ui/core/styles";
import useNotify from "../../hooks/use-notify";
import { sendCSVMail } from "./services";
import DateRangePicker, { useDateRange } from "../DateRangePicker";

const styles = () =>
	createStyles({
		emailModal: {
			textAlign: "center",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexDirection: "column",
			gap: "0.5rem",
			"& img": {
				maxHeight: "100px",
			},
		},
		modalCloseBtn: {
			position: "absolute",
			top: "0.5rem",
			right: "0.5rem",
		},
		emailForm: {
			textAlign: "center",
			display: "flex",
			justifyContent: "center",
			flexDirection: "column",
			gap: "1rem",
			width: "100%",
			marginTop: "0.5rem",
		},
		checkboxLabel: {
			textAlign: "start",
			alignItems: "flex-start",
			"& .MuiTypography-body1": {
				fontSize: "0.9rem",
			},
			"& .MuiButtonBase-root": {
				padding: "0 9px",
			},
		},
	});

const useStyles = makeStyles(styles);

const SendCSVModal: React.FC<SendCSVDialogProps> = ({
	openModal,
	setOpenModal,
	reqBody,
	url,
	passDateRange,
}) => {
	const [agreedToTerms, setagreedToTerms] = useState(false);
	const [email, setEmail] = useState("");
	const [sendingMail, setSendingMail] = useState(false);
	const dateRange = useDateRange();
	const classes = useStyles();

	let notify = useNotify();
	const handleSubmitMail = async () => {
		setSendingMail(true);
		const fromTo = passDateRange
			? { start_date: dateRange.from, end_date: dateRange.to }
			: {};
		try {
			let res = await sendCSVMail(url, {
				email,
				...fromTo,
				...reqBody,
			});
			notify(res.message);
			setEmail("");
			setOpenModal(false);
		} catch (error) {
			notify(error as Error);
		} finally {
			resetState();
		}
	};

	const resetState = () => {
		setEmail("");
		setSendingMail(false);
		setagreedToTerms(false);
	};
	return (
		<Dialog
			maxWidth="xs"
			onClose={() => {
				setOpenModal(false);
				resetState();
			}}
			open={openModal}
		>
			<IconButton
				onClick={() => {
					setagreedToTerms(false);
					setOpenModal(false);
				}}
				className={classes.modalCloseBtn}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent>
				<Box className={classes.emailModal} py={4}>
					<img src={EmailIcon} alt="Send mail icon" />
					<Typography variant="h5">Enter your email</Typography>
					<Typography variant="body1">
						The csv file will be sent to the provided email address.
					</Typography>
					<form
						className={classes.emailForm}
						onSubmit={async ev => {
							ev.preventDefault();
							handleSubmitMail();
						}}
					>
						<TextField
							variant="outlined"
							fullWidth
							label="Email Address"
							type="email"
							value={email}
							disabled={sendingMail}
							onChange={ev => setEmail(ev.target.value)}
							placeholder="Enter Email Address"
							inputProps={{
								tabIndex: 8,
								height: "45px",
							}}
						/>
						{passDateRange && <DateRangePicker asInput />}
						<FormControlLabel
							color="primary"
							className={classes.checkboxLabel}
							control={
								<Checkbox
									color="primary"
									value={agreedToTerms}
									onClick={() => setagreedToTerms(!agreedToTerms)}
								/>
							}
							label="By clicking this box you are confirming that you have access to the provided email"
						/>
						<Button
							type="submit"
							variant={sendingMail ? "text" : "contained"}
							color="primary"
							tabIndex={7}
							style={{ height: 45 }}
							fullWidth
							disabled={
								sendingMail || agreedToTerms === false || !!email.trim() === false
							}
						>
							{sendingMail ? "Loading..." : "Submit"}
						</Button>
					</form>
				</Box>
			</DialogContent>
		</Dialog>
	);
};
export default SendCSVModal;
