import React, { useState, useCallback, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DistributionReportProps } from "./types";
import {
	Box,
	Card,
	CardHeader,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	makeStyles,
} from "@material-ui/core";
import styles from "../styles";
import { useDateRange } from "../../../core/components/DateRangePicker";
import moment from "moment";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import { todaysDateRange } from "../ReportsSection";
import { getPlace, Place } from "../../../core/system/places";
import { renderError } from "../../../core/providers/PlaceReportProvider/PlaceReportProvider";

NoDataToDisplay(Highcharts);
const useStyles = makeStyles(styles);

const getStationIds = (stations: any[]) => stations.map(el => el.id);

const DistributionReport: React.FC<DistributionReportProps> = props => {
	const classes = useStyles();

	const stations = React.useMemo(
		() => getStationIds(props.stations),
		[props.stations]
	);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [reports, setReports] = useState<Place[]>([]);

	const dateRange = useDateRange();

	const loadReports = useCallback(
		async (startDate?: Date, endDate?: Date) => {
			try {
				setError(null);
				setLoading(true);

				const newReports = await Promise.allSettled(
					stations.map(async stationId => {
						try {
							const response = await getPlace(stationId, {
								start_date: startDate,
								end_date: endDate,
							});
							return response;
						} catch (error) {
							console.error(`Failed to fetch station ${stationId}:`, error);
							return null;
						}
					})
				);

				const successfulReports = newReports
					.filter(
						(result): result is PromiseFulfilledResult<Place> =>
							result.status === "fulfilled" && result.value !== null
					)
					.map(result => result.value);

				setReports(successfulReports);
			} catch (err) {
				console.error("Failed to load reports:", err);
				setError(err as Error);
			} finally {
				setLoading(false);
			}
		},
		[stations]
	);

	// Effect for today's data
	useEffect(() => {
		if (!props.qtyToday) return;
		loadReports(todaysDateRange.from, todaysDateRange.to);
	}, [loadReports, props.qtyToday]);

	// Effect for date range data
	useEffect(() => {
		if (props.qtyToday) return;
		loadReports(dateRange.from, dateRange.to);
	}, [dateRange.from, dateRange.to, loadReports, props.qtyToday]);

	const categories = reports.map(el => el.name);

	const data = reports.map(el => el?.lp_qty);

	const [selectedIndices, setSelectedIndices] = useState<number[]>(
		Array.from(Array(categories.length).keys())
	);

	const updateSelectedIndices = useCallback((index: number) => {
		setSelectedIndices(prev => {
			if (prev.includes(index)) {
				return prev.filter(i => i !== index);
			} else {
				return [...prev, index];
			}
		});
	}, []);

	const chartOptions = {
		chart: {
			type: "column",
		},
		noData: {
			style: {
				fontWeight: "bold",
				fontSize: "24px",
			},
		},
		title: {
			text: "",
		},
		xAxis: {
			categories: selectedIndices.map(i => categories[i]),
			crosshair: true,
		},
		yAxis: {
			min: 0,
			title: {
				text: "Number of items",
			},
		},
		plotOptions: {
			column: {
				pointPadding: 0.2,
				borderWidth: 0,
			},
		},
		series: [
			{
				name: "Stations",
				data: selectedIndices.map(i => data[i]),
				colorByPoint: true,
			},
		],
		exporting: {
			buttons: {
				contextButton: {
					menuItems: [
						"downloadPNG",
						"downloadSVG",
						"separator",
						"downloadCSV",
						"downloadXLS",
					],
				},
			},
		},
	};

	useEffect(() => {
		setSelectedIndices(Array.from(Array(categories.length).keys()));
	}, [categories.length]);

	return (
		<Card
			style={{
				background: "white",
				padding: "1rem",
			}}
			className={classes.root}
		>
			<CardHeader
				title={
					dateRange?.from && !props.qtyToday
						? `Location Distribution Report (${moment(dateRange?.from).format(
								"MMM DD"
						  )} - ${moment(dateRange?.to).format("MMM DD")})`
						: `Location Distribution Report ${props.qtyToday ? "Today" : ""}`
				}
			/>
			{error && renderError(error, () => {})}
			{loading ? (
				<Box
					style={{ display: "flex", height: "100px" }}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<CircularProgress />
				</Box>
			) : (
				<>
					<div id="container" style={{ width: "100%", height: "400px" }}>
						<HighchartsReact highcharts={Highcharts} options={chartOptions} />
					</div>
					{categories.length > 0 && (
						<div id="controls">
							<h3>Select Locations:</h3>
							{categories.map((category, index) => (
								<FormControlLabel
									key={category + index}
									control={
										<Checkbox
											name="Stations"
											value={index}
											checked={selectedIndices.includes(index)}
											onChange={() => updateSelectedIndices(index)}
											color="secondary"
										/>
									}
									label={category}
								/>
							))}
						</div>
					)}
				</>
			)}
		</Card>
	);
};

export default DistributionReport;
