import React from "react";
import { PlaceReportProviderProps, PlaceReport } from "./types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import RetryIcon from "@material-ui/icons/Replay";

import { useState, useEffect } from "react";
import context from "./context";
import { getStationHealthReport } from "../../system/reports";
import { Cache } from "../../system/store";

const reportsCache = new Cache();

export const renderLoading = () => (
	<Box px={2} py={5} textAlign="center">
		<CircularProgress size={24} />
	</Box>
);

export const renderError = (err: Error, retry: () => void) => (
	<Box px={2} py={5} textAlign="center">
		<Typography gutterBottom>{err.message}</Typography>
		<Button startIcon={<RetryIcon />} onClick={retry}>
			Retry
		</Button>
	</Box>
);

export const renderReloading = () => (
	<LinearProgress
		style={{ position: "absolute", width: "100%", left: 0, zIndex: 1 }}
	/>
);

const PlaceReportProvider: React.FC<PlaceReportProviderProps> = props => {
	const { id, reloadInterval, dateRange } = props;

	const [placeReport, setPlaceReport] = useState<PlaceReport | null>(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [retryTimestamp, setRetryTimestamp] = useState(0);

	const isReloading = loading && !!placeReport && placeReport.id === id;

	useEffect(() => {
		let timeoutHandle: NodeJS.Timeout | null = null;

		(async function loadReport() {
			if (reloadInterval) {
				timeoutHandle && clearTimeout(timeoutHandle); // Clear any pre set timer
				timeoutHandle = setTimeout(loadReport, reloadInterval);
			}

			try {
				setError(null);
				setLoading(true);

				const maxAgeAccepted = (reloadInterval || 0) / 4;
				const report = await (reportsCache.get<PlaceReport>(id, maxAgeAccepted) ||
					reportsCache
						.set(
							id,
							getStationHealthReport(id, {
								start_date: dateRange?.from,
								end_date: dateRange?.to,
							})
						)
						.get<PlaceReport>(id));
				setPlaceReport(report as PlaceReport);
				setLoading(false);
			} catch (err) {
				console.log(err);
				setLoading(false);
				setError(err as Error);
			}
		})();

		return () => {
			timeoutHandle && clearTimeout(timeoutHandle);
			reportsCache.delete(id);
		};
	}, [dateRange?.from, dateRange?.to, id, reloadInterval, retryTimestamp]);

	return (
		<context.Provider value={placeReport}>
			{loading && !isReloading && renderLoading()}
			{isReloading && renderReloading()}
			{error && renderError(error, () => setRetryTimestamp(Date.now()))}
			{!error && (!loading || isReloading) && props.children}
		</context.Provider>
	);
};

export default PlaceReportProvider;
