import React, { useEffect, useMemo, useState, forwardRef } from "react";
import { DataGridProps, DataGridRef, RowModel, ColumnDef } from "./types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
// import TablePagination from '@material-ui/core/TablePagination';
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./styles";
import { Box, TablePagination, Typography } from "@material-ui/core";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
const useStyles = makeStyles(styles);

const DataGrid: React.ForwardRefRenderFunction<DataGridRef, DataGridProps> =
	function (props, ref) {
		const {
			rows,
			columns,
			disableSelectionOnClick = false,
			checkboxSelection = false,
			onSelectionChange = () => {},
			onPageChange = () => {},
			rowCount = 0,
			pageSize = 0,
			rowsPerPageOptions = [20],
			windowHeight = "100%",
			loading = false,
			isPaginated = false,
		} = props;

		const classes = useStyles();
		const [selection, setSelection] = useState<RowModel["id"][]>([]);
		const [page, setPage] = useState(0);
		const [order, setOrder] = useState<"asc" | "desc">("desc");
		const [orderBy, setOrderBy] = useState("");

		const columnsMap = useMemo(() => {
			const map: { [key: string]: ColumnDef } = {};

			columns.forEach(column => (map[column.field] = column));
			return map;
		}, [columns]);

		const rowModels: RowModel[] = useMemo(() => {
			const rowModels = rows.map((row, i) => ({
				id: row?.id || `row_${i}`,
				data: row,
				selected: selection.includes(row?.id || `row_${i}`),
			}));

			if (orderBy) {
				const columnDef = columnsMap[orderBy];
				rowModels.sort((a, b) => {
					const valA = columnDef.valueGetter
						? columnDef.valueGetter(a.data)
						: a.data[columnDef.field];
					const valB = columnDef.valueGetter
						? columnDef.valueGetter(b.data)
						: b.data[columnDef.field];

					if (order === "desc") return valA > valB ? -1 : valA === valB ? 0 : 1;
					else return valA > valB ? 1 : valA === valB ? 0 : -1;
				});
			}

			return rowModels;
		}, [rows, selection, order, orderBy, columnsMap]);

		// Flags
		const allRowsSelected = rows.length > 0 && selection.length === rows.length;
		const someRowsSelected =
			selection.length > 0 && selection.length < rows.length;
		const dropPage =
			page > 0 && (page + 1) * pageSize === rowCount && rows.length === 0;

		// Pre-calculate row IDs once to avoid repeated mapping
		const rowIds = useMemo(
			() => rows.map((el, i) => el?.id || `row_${i}`),
			[rows]
		);

		function handleRowClick(rowModel: RowModel) {
			if (disableSelectionOnClick && !checkboxSelection) return;
			setSelection(currentSelection => {
				const isSelected = currentSelection.includes(rowModel.id);
				const newSelection = isSelected
					? currentSelection.filter(id => id !== rowModel.id)
					: [...currentSelection, rowModel.id];

				const selectedRows = rows.filter((row, i) =>
					newSelection.includes(row?.id || `row_${i}`)
				);
				onSelectionChange(selectedRows);
				return newSelection;
			});
		}

		function handleCheckboxSelection() {
			const newSelection =
				someRowsSelected || selection.length === 0 ? rowIds : [];
			setSelection(newSelection);
			const selectedRows = newSelection.length
				? rows.filter((row, i) => newSelection.includes(row?.id || `row_${i}`))
				: [];

			onSelectionChange(selectedRows);
		}

		function sortHandler(fieldName: string) {
			return () => {
				const isAsc = fieldName === orderBy && order === "asc";
				setOrder(isAsc ? "desc" : "asc");
				setOrderBy(fieldName);
			};
		}

		// useImperativeHandle(ref, () => ({ setSelection }), []);

		useEffect(() => {
			if (dropPage && page > 0) {
				const newPage = page - 1;
				if (newPage !== page) {
					setPage(newPage);
					onPageChange(newPage);
				}
			}
		}, [dropPage, onPageChange, page]);

		return (
			<>
				<div className={classes.root} style={{ height: windowHeight }}>
					{loading && (
						<div className={classes.loadingOverlay}>
							<div className={classes.circularProgressContainer}>
								<CircularProgress />
								<p>Loading data...</p>
							</div>
						</div>
					)}
					<TableContainer component={Paper} style={{ height: "100%" }}>
						<Table style={props.tableStyle} stickyHeader className={classes.table}>
							<TableHead>
								<TableRow>
									{checkboxSelection && (
										<TableCell className={classes.tableCell} padding="checkbox">
											<Checkbox
												checked={allRowsSelected}
												indeterminate={someRowsSelected}
												onChange={handleCheckboxSelection}
											/>
										</TableCell>
									)}
									{columns.map((column, i) => (
										<TableCell
											className={classes.tableCell}
											key={column.field + i}
											style={{ minWidth: column.width }}
											align={column.align}
											sortDirection={orderBy === column.field ? order : false}
										>
											{column.sortable ? (
												<TableSortLabel
													active={orderBy === column.field}
													direction={orderBy === column.field ? order : "asc"}
													onClick={sortHandler(column.field)}
													title={`Sort ${column.headerName}`}
												>
													<b>{column.headerName || column.field}</b>
												</TableSortLabel>
											) : (
												<b>{column.headerName || column.field}</b>
											)}
										</TableCell>
									))}
								</TableRow>
							</TableHead>

							<TableBody>
								{!loading && rowModels.length <= 0 ? (
									<TableRow>
										<TableCell colSpan={columns.length + (checkboxSelection ? 1 : 0)}>
											<Box
												className={classes.noData}
												alignItems="center"
												justifyContent={"center"}
												flexDirection={"column"}
												style={{ display: "flex" }}
											>
												<InsertDriveFileIcon />
												<Typography>No Data Found</Typography>
											</Box>
										</TableCell>
									</TableRow>
								) : (
									<>
										{rowModels.map((rowModel, i) => (
											<TableRow
												key={`${i}-${rowModel?.id}`}
												selected={rowModel.selected}
												onClick={ev => {
													ev.stopPropagation();
													if (disableSelectionOnClick) return;
													handleRowClick(rowModel);
												}}
												hover
											>
												{checkboxSelection && (
													<TableCell className={classes.tableCell} padding="checkbox">
														<Checkbox
															checked={rowModel.selected}
															onClick={ev => {
																ev.stopPropagation();
																handleRowClick(rowModel);
															}}
														/>
													</TableCell>
												)}
												{columns.map((column, i) => (
													<TableCell
														className={classes.tableCell}
														key={column.field + i}
														align={column.align}
														padding={column.padding}
													>
														{column.renderCell
															? column.renderCell(rowModel.data)
															: column.valueGetter
															? column.valueGetter(rowModel.data)
															: rowModel.data[column.field]}
													</TableCell>
												))}
											</TableRow>
										))}
									</>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</div>

				{isPaginated && (
					<TablePagination
						component="div"
						count={rowCount}
						rowsPerPage={pageSize}
						rowsPerPageOptions={rowsPerPageOptions}
						page={page}
						onPageChange={(ev, page) => {
							setPage(page);
							onPageChange(page + 1);
							setSelection([]);
							onSelectionChange([]);
						}}
					/>
				)}
			</>
		);
	};

export default forwardRef(DataGrid);
