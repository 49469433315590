import { useState, forwardRef, useImperativeHandle, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { EditContainerQuantityDialogComponent } from "./types";
import { DialogContent, TextField } from "@material-ui/core";
import { StackType, generateStackSheets } from "../core/system/stacks";
import EmbeddedContentDialog, {
	EmbeddedContentDialogApi,
} from "../core/components/EmbeddedContentDialog";

const EditContainerQuantityDialog: EditContainerQuantityDialogComponent = (
	{ onCloseDialog = () => {} },
	ref
) => {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [stack, setStack] = useState<StackType>();
	const [qty, setQty] = useState<number>(stack?.item_count || 0);

	useImperativeHandle(
		ref,
		() => ({
			openDialog: async (data: StackType) => {
				setOpen(true);
				setLoading(false);
				setStack(data);
			},
			closeDialog: () => {
				setOpen(false);
			},
		}),
		[]
	);

	const sheetsDialog = useRef<EmbeddedContentDialogApi>(null);

	return (
		<>
			<Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
				<DialogTitle>Change Container Quantity</DialogTitle>
				<DialogContent>
					<form
						onSubmit={async ev => {
							ev.preventDefault();
							if (stack) {
								sheetsDialog.current?.openDialog(() =>
									generateStackSheets([{ ...stack, item_count: qty }], true)
								);
							}
						}}
					>
						<TextField
							variant="outlined"
							fullWidth
							label="Quantity"
							color="secondary"
							type="number"
							value={qty}
							disabled={loading}
							onChange={ev => setQty(Number(ev.target.value))}
							inputProps={{
								tabIndex: 8,
								height: "45px",
							}}
						/>
						<Button
							type="submit"
							variant={loading ? "text" : "contained"}
							color="secondary"
							tabIndex={7}
							style={{ height: 45, margin: "1rem 0 1rem" }}
							fullWidth
							disabled={loading !== false || !qty}
						>
							{loading ? "Loading..." : "Generate Label"}
						</Button>
					</form>
				</DialogContent>
			</Dialog>
			<EmbeddedContentDialog
				ref={sheetsDialog}
				onCloseDialog={() => setOpen(false)}
				title="Stack Sheets"
			/>
		</>
	);
};

export default forwardRef(EditContainerQuantityDialog);
