import React from "react";
import { CustomRangePickerDialogProps } from "./types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import { DateTimePicker } from "@material-ui/pickers";

import moment from "moment/moment";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { DateRange } from "../DateRangeProvider";

const useStyles = makeStyles(styles);

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	);
}

const CustomRangePickerDialog: React.FC<
	CustomRangePickerDialogProps
> = props => {
	const {
		initialValues = { from: new Date(), to: new Date() },
		onDone,
		onCancel,
		children,
		...dialogProps
	} = props;

	const [dateRange, setDateRange] = useState<DateRange | undefined>(
		initialValues
	);
	const [currTab, setCurrTab] = useState<"from-date" | "to-date">("from-date");
	// Update initial value on dialog open
	useEffect(() => {
		if (dialogProps.open) {
			setDateRange(initialValues);
			setCurrTab("from-date");
		}
	}, [dialogProps.open, initialValues]);

	const classes = useStyles();

	return (
		<Dialog {...dialogProps}>
			<Tabs
				variant="fullWidth"
				indicatorColor="primary"
				value={currTab}
				onChange={(ev, val) => setCurrTab(val)}
				classes={{ indicator: classes.activeTabIndicator }}
			>
				<Tab
					label={
						<>
							From
							<br />
							{moment(dateRange?.from).format("MMM D, YYYY")}
						</>
					}
					value="from-date"
					classes={{ selected: classes.activeTab }}
				/>
				<Tab
					label={
						<>
							To
							<br />
							{moment(dateRange?.to).format("MMM D, YYYY")}
						</>
					}
					value="to-date"
					classes={{ selected: classes.activeTab }}
				/>
			</Tabs>
			<DialogContent className={classes.dialogContentRoot}>
				<TabPanel value="from-date" index={currTab}>
					<DateTimePicker
						variant="static"
						value={dateRange?.from}
						onChange={date =>
							date &&
							setDateRange(curr =>
								curr ? { ...curr, from: date.toDate() } : undefined
							)
						}
					/>
				</TabPanel>
				<TabPanel value="to-date" index={currTab}>
					<DateTimePicker
						variant="static"
						value={dateRange?.to}
						onChange={date =>
							date &&
							setDateRange(curr => (curr ? { ...curr, to: date.toDate() } : undefined))
						}
					/>
				</TabPanel>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel}>Cancel</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={() => onDone(dateRange)}
				>
					Done
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CustomRangePickerDialog;
