import React, { Fragment, useState } from "react";
import { MainSideNavProps } from "./types";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import Layout, { Header, Content, Footer } from "../Layout";
import PersonBadge from "../PersonBadge";
import { NavLink } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import SvgIcon from "@material-ui/core/SvgIcon";
import styles from "./styles";
import clsx from "clsx";
import productionTrackLogo from "../../../assets/images/logo-productiontrack.png";
import { ReactComponent as ProductIconSvg } from "../../../assets/icons/product.svg";
import { ReactComponent as PlaceIconSvg } from "../../../assets/icons/place.svg";
import { ReactComponent as PersonIconSvg } from "../../../assets/icons/person.svg";
import { ReactComponent as DocumentIconSvg } from "../../../assets/icons/document.svg";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
// import ListAltIcon from "@material-ui/icons/ListAlt";
import { Collapse } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CropFreeIcon from "@material-ui/icons/CropFree";

import HorizontalSplitIcon from "@material-ui/icons/HorizontalSplit";
const navItems = [
	{
		id: 0,
		label: "Dashboard",
		icon: <Icon fontSize="inherit">dashboard</Icon>,
		path: "/dashboard",
		children: [],
	},
	{
		id: 1,
		label: "Reports",
		icon: <Icon fontSize="inherit">assessment</Icon>,
		path: "/reports",
		children: [],
	},
	{
		id: 2,
		label: "Search products",
		icon: <Icon fontSize="inherit">find_in_page</Icon>,
		path: "/products-lookup",
		children: [],
	},
	{
		id: 3,
		label: "Orders",
		activeWhenExact: true,
		icon: (
			<SvgIcon fontSize="inherit">
				<DocumentIconSvg />
			</SvgIcon>
		),
		children: [
			{
				id: "3.1",
				label: "View orders",
				icon: (
					<SvgIcon fontSize="inherit">
						<DocumentIconSvg />
					</SvgIcon>
				),
				path: "/orders",
			},
			{
				id: "3.2",
				label: "Create new order",
				icon: <Icon fontSize="inherit">add</Icon>,
				path: "/orders/new",
			},
		],
	},
	{
		id: 10,
		label: "Containers",
		icon: (
			<SvgIcon fontSize="inherit">
				<HorizontalSplitIcon />
			</SvgIcon>
		),
		path: "/stacks",
		children: [],
	},
	{
		id: 4,
		label: "Places",
		icon: (
			<SvgIcon fontSize="inherit">
				<PlaceIconSvg />
			</SvgIcon>
		),
		path: "/places",
		children: [],
	},
	{
		id: 5,
		label: "Products",
		icon: (
			<SvgIcon fontSize="inherit">
				<ProductIconSvg />
			</SvgIcon>
		),
		path: "/products",
		children: [],
	},
	{
		id: 6,
		label: "Vendors",
		icon: <Icon fontSize="inherit">domain_icon</Icon>,
		path: "/vendors",
		children: [],
	},
	{
		id: 9,
		label: "Bin Families",
		icon: <Icon fontSize="inherit">shopping_basket_icon</Icon>,
		children: [
			{
				id: "9.1",
				label: "Scan Bins",
				icon: <CropFreeIcon />,
				path: "/scan-bin",
			},
			{
				id: "9.2",
				label: "Bin Families",
				icon: <Icon fontSize="inherit">shopping_basket_icon</Icon>,
				path: "/bin-families",
			},
			{
				id: "9.3",
				label: "Create Order Plates",
				icon: <Icon fontSize="inherit">shopping_basket_icon</Icon>,
				path: "/order-plates",
			},
			{
				id: "9.4",
				label: "Shopping Lists",
				icon: <ShoppingCartIcon />,
				path: "/shopping-lists",
			},
		],
	},
	{
		id: 7,
		label: "People",
		icon: (
			<SvgIcon fontSize="inherit">
				<PersonIconSvg />
			</SvgIcon>
		),
		path: "/persons",
		children: [],
	},

	{
		id: 8,
		label: "Training",
		icon: <Icon fontSize="inherit">school</Icon>,
		path: "/litmon",
		children: [],
	},
];

const useStyles = makeStyles(styles);

const MainSideNav: React.FC<MainSideNavProps> = props => {
	const [person, , { logout }] = useAuthentication();
	const classes = useStyles();
	const [openSubmenu, setOpenSubMenu] = useState("");
	return (
		<Layout bgcolor="grey.100">
			<Header mb={5}>
				<Toolbar>
					<img
						alt="Production track trademark"
						src={productionTrackLogo}
						height={40}
					/>
				</Toolbar>
			</Header>
			<Content>
				<List>
					{navItems.map((item: any) => (
						<Fragment key={"" + item.id}>
							<ListItem
								button
								component={item.path ? NavLink : "li"}
								onClick={() =>
									item.children.length <= 0
										? props.onItemClick && props.onItemClick()
										: setOpenSubMenu(prev => (item.label === prev ? "" : item.label))
								}
								to={item.path}
								exact={item.activeWhenExact}
								className={classes.listItem}
								activeClassName={classes.activeNavListItem}
							>
								{item.icon && (
									<ListItemIcon className={classes.listItemIcon}>
										{item.icon}
									</ListItemIcon>
								)}
								<ListItemText
									className={classes.listItemText}
									// primaryTypographyProps={{align: "right"}}
									primary={item.label}
								/>

								{item.children.length > 0 && (
									<ListItemIcon
										style={{ justifyContent: "flex-end" }}
										className={classes.listItemIcon}
									>
										{openSubmenu === item.label ? <ExpandLess /> : <ExpandMore />}
									</ListItemIcon>
								)}
							</ListItem>
							{item.children.length > 0 && (
								<>
									<Collapse in={openSubmenu === item.label} timeout="auto" unmountOnExit>
										<List style={{ paddingLeft: "50px" }} component="div" disablePadding>
											{item.children.map((childItem: any) => (
												<ListItem
													key={childItem.id}
													button
													onClick={() => props.onItemClick && props.onItemClick()}
													component={NavLink}
													to={childItem.path}
													exact
													className={clsx(classes.listItem, classes.listItemChild)}
													activeClassName={classes.activeNavListItem}
												>
													{childItem.icon && (
														<ListItemIcon className={classes.listItemIcon}>
															{childItem.icon}
														</ListItemIcon>
													)}
													<ListItemText
														className={classes.listItemText}
														// primaryTypographyProps={{align: "right"}}
														inset={!childItem.icon}
														primary={childItem.label}
													/>
												</ListItem>
											))}
										</List>
									</Collapse>
								</>
							)}
						</Fragment>
					))}
				</List>
			</Content>
			<Footer>
				{!!person && (
					<Box px={2} py={1}>
						<PersonBadge
							name={`${person.first_name} ${person.last_name}`}
							email={person.email}
						/>
					</Box>
				)}
				<Box px={2} pt={1}>
					<NavLink to="/settings/profile" style={{ textDecoration: "none" }}>
						<Button startIcon={<Icon>settings</Icon>}>Settings</Button>
					</NavLink>
				</Box>
				<Box px={2} py={1} mb={1}>
					<Button
						onClick={logout}
						size="small"
						color="secondary"
						startIcon={<Icon>logout</Icon>}
					>
						Logout
					</Button>
				</Box>
				<Divider light />
				<Box px={2} py={1}>
					<Typography color="textSecondary" variant="caption">
						&copy; {new Date().getFullYear()} - Moment track
					</Typography>
				</Box>
			</Footer>
		</Layout>
	);
};

export default MainSideNav;
