import React, {
	useState,
	useImperativeHandle,
	useRef,
	forwardRef,
} from "react";
import { BinFamilyDialogProps, BinFamilyDialogRef } from "./types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import BinFamilyForm, { BinFamilyFormRef } from "../core/forms/BinFamilyForm";
import useNotify from "../core/hooks/use-notify";
import { BinFamilyInputData } from "../core/system/bin-families";

const BinFamilyDialog: React.ForwardRefRenderFunction<
	BinFamilyDialogRef,
	BinFamilyDialogProps
> = function (props, ref) {
	const {
		binFamily,
		title = "Bin family",
		primaryActionText = "Done",
		secondaryActionText = "Close",
		primaryAction = () => {},
		secondaryAction = () => {},
	} = props;

	const binFamilyForm = useRef<BinFamilyFormRef>(null);
	const notify = useNotify();
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [editable, setEditable] = useState(false);

	async function handlePrimaryAction(data: BinFamilyInputData) {
		try {
			setLoading(true);
			await primaryAction(data);
			setOpen(false);
		} catch (err) {
			notify(err as Error);
		} finally {
			setLoading(false);
		}
	}

	async function handleSecondaryAction() {
		try {
			setLoading(true);
			await secondaryAction();
			setOpen(false);
		} catch (err) {
			notify(err as Error);
		} finally {
			setLoading(false);
		}
	}

	useImperativeHandle(
		ref,
		() => {
			function openDialog(editable = false) {
				setEditable(editable);
				setOpen(true);
			}

			return { openDialog };
		},
		[]
	);

	return (
		<Dialog
			open={open}
			fullWidth
			maxWidth="sm"
			disableEscapeKeyDown={loading}
			onClose={() => setOpen(false)}
		>
			<DialogTitle>{title}</DialogTitle>
			{!editable && <Divider />}
			<DialogContent>
				<Box pt={2} pb={4}>
					{editable && (
						<BinFamilyForm
							ref={binFamilyForm}
							initialValues={binFamily}
							onSubmit={handlePrimaryAction}
							disabled={loading}
						/>
					)}

					{!editable && !!binFamily && (
						<Grid container spacing={4}>
							<Grid item xs={6}>
								<Typography variant="caption" color="textSecondary">
									PRODUCT
								</Typography>
								<Typography variant="body1">{binFamily.product.name}</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="caption" color="textSecondary">
									DEFAULT QTY
								</Typography>
								<Typography variant="body1">{binFamily.default_quantity}</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="caption" color="textSecondary">
									PLACE
								</Typography>
								<Typography variant="body1">{binFamily.location.name}</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="caption" color="textSecondary">
									VENDOR
								</Typography>
								<Typography variant="body1">
									{binFamily.preferred_vendor.name}
								</Typography>
							</Grid>
						</Grid>
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				{loading && <CircularProgress size={24} color="primary" />}

				<Button disabled={loading} onClick={handleSecondaryAction}>
					{secondaryActionText}
				</Button>

				{editable && (
					<Button
						disabled={loading}
						variant="contained"
						color="primary"
						onClick={() => binFamilyForm.current?.submitForm()}
					>
						{primaryActionText}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default forwardRef(BinFamilyDialog);
