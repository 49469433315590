import React, {
	useState,
	useImperativeHandle,
	useRef,
	forwardRef,
} from "react";
import { VendorDialogProps, VendorDialogRef } from "./types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import useNotify from "../../core/hooks/use-notify";
import VendorForm, { VendorFormRef } from "../../core/forms/VendorForm";
import { VendorInputData } from "../../core/system/vendors";

const VendorDialog: React.ForwardRefRenderFunction<
	VendorDialogRef,
	VendorDialogProps
> = function (props, ref) {
	const {
		vendor,
		title = "Vendor",
		primaryActionText = "Done",
		secondaryActionText = "Close",
		primaryAction = () => {},
		secondaryAction = () => {},
	} = props;

	const vendorForm = useRef<VendorFormRef>(null);
	const notify = useNotify();
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [editable, setEditable] = useState(false);

	async function handlePrimaryAction(data: VendorInputData) {
		try {
			setLoading(true);
			await primaryAction(data);
			setOpen(false);
		} catch (err) {
			notify(err as Error);
		} finally {
			setLoading(false);
		}
	}

	async function handleSecondaryAction() {
		try {
			setLoading(true);
			await secondaryAction();
			setOpen(false);
		} catch (err) {
			notify(err as Error);
		} finally {
			setLoading(false);
		}
	}

	useImperativeHandle(
		ref,
		() => {
			function openDialog(editable = false) {
				setEditable(editable);
				setOpen(true);
			}

			return { openDialog };
		},
		[]
	);

	return (
		<Dialog
			open={open}
			fullWidth
			maxWidth="sm"
			disableEscapeKeyDown={loading}
			onClose={() => setOpen(false)}
		>
			<DialogTitle>{title}</DialogTitle>
			{!editable && <Divider />}
			<DialogContent>
				<Box pt={2} pb={4}>
					{editable && (
						<VendorForm
							ref={vendorForm}
							initialValues={
								vendor
									? ({
											erp_number: vendor.erp_number,
											name: vendor.name,
									  } as VendorInputData)
									: undefined
							}
							onSubmit={handlePrimaryAction}
							disabled={loading}
						/>
					)}

					{!editable && !!vendor && (
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant="caption" color="textSecondary">
									NAME
								</Typography>
								<Typography style={{ wordWrap: "break-word" }} variant="body1">
									{vendor.name}
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="caption" color="textSecondary">
									ERP NUMBER
								</Typography>
								<Typography style={{ wordWrap: "break-word" }} variant="body1">
									{vendor.erp_number}
								</Typography>
							</Grid>
						</Grid>
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				{loading && <CircularProgress size={24} color="primary" />}

				<Button disabled={loading} onClick={handleSecondaryAction}>
					{secondaryActionText}
				</Button>

				{editable && (
					<Button
						disabled={loading}
						variant="contained"
						color="primary"
						onClick={() => vendorForm.current?.submitForm()}
					>
						{primaryActionText}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default forwardRef(VendorDialog);
